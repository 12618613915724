import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Typography from '@material-ui/core/Typography'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Page from '@components/Page'

const IndexPage = ({ data }) => {
  const { mdx } = data
  const { frontmatter, body } = mdx

  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <Page>
        <Typography variant="h1">{frontmatter.title}</Typography>

        <MDXRenderer>{body}</MDXRenderer>
      </Page>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    mdx(frontmatter: { slug: { eq: "home" } }) {
      body
      frontmatter {
        title
      }
    }
  }
`
